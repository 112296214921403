<template>
    <SectionVideo />
    <SectionHistory />
    <SectionValue />
    <SectionVision />
    <SectionThumbs />
    <SectionAchievement />
    <SectionCertification />
</template>

<script setup lang="ts">
import SectionVideo from './SectionVideo.vue'
import SectionHistory from './SectionHistory.vue'
import SectionValue from './SectionValue.vue'
import SectionVision from './SectionVision.vue'
import SectionThumbs from './SectionThumbs.vue'
import SectionAchievement from './SectionAchievement.vue'
import SectionCertification from './SectionCertification.vue'

useDirectusCollectionSeo('about', {})
onMounted(() => {
    var tag = document.createElement('script')
    tag.src = 'https://www.youtube.com/iframe_api'
    var firstScriptTag = document.getElementsByTagName('script')[1]
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
})
</script>

<style></style>
