<template>
    <section class="py-12 lg:py-24">
        <div class="container">
            <div class="text-2xl sm:text-35px lg:text-5xl text-[#E6AF5D] font-medium mb-6 lg:mb-24 text-center">
                <v-content option_key="about_achievement_title" />
            </div>
            <div class="">
                <v-list
                    option_key="about_achievement_list"
                    :icons="[]"
                    :fields="['year', 'title', 'content']"
                    v-slot="{ item, index }"
                >
                    <div
                        v-show="showAll || is_edit ? index >= 0 : index < 3"
                        class="flex flex-col md:flex-row items-center gap-3 md:gap-6 lg:gap-25 py-5 lg:py-10 border-b border-solid border-[#D9D9D9] overflow-x-auto"
                    >
                        <div class="w-full lg:max-w-1/12">
                            <v-content :option_key="item['year']" />
                        </div>
                        <div class="w-full lg:max-w-4/12">
                            <v-content :option_key="item['title']" />
                        </div>
                        <div class="w-full lg:max-w-7/12">
                            <v-content :option_key="item['content']" />
                        </div>
                    </div>
                </v-list>
            </div>
            <div
                v-if="!is_edit && !showAll && checkShowButton()"
                @click="showAll = true"
                class="btn-effect-dark w-max flex items-center gap-3 text-white bg-blacks-100 py-4 px-9 mx-auto cursor-pointer mt-8 lg:mt-15"
            >
                <p>Xem tất cả</p>
            </div>
            <div
                v-if="showAll && checkShowButton()"
                @click="showAll = false"
                class="btn-effect-dark w-max flex items-center gap-3 text-white bg-blacks-100 py-4 px-9 mx-auto cursor-pointer mt-8 lg:mt-15"
            >
                <p>Thu gọn</p>
            </div>
        </div>
    </section>
</template>
<script setup lang="ts">
const showAll = ref(false)
const is_edit = inject('is_edit')
const data_live_editor = inject('data_live_editor')
const lengthList = ref(getDataGlobalByKey('vi-VN', `LIST__about_achievement_list`)?.option_value)
// const dataAchievement = data_live_editor?.value.filter((item) => {
//     return item.option_key.includes('CONTENT__about_achievement_list')
// })
const checkShowButton = () => {
    return JSON.parse(lengthList.value)?.length > 3
}
</script>
