<template>
    <section class="bg-[#F8F8F8]">
        <div class="container py-12 lg:py-24">
            <div class="flex flex-col lg:flex-row items-center gap-4 lg:gap-17">
                <h3
                    class="lg:w-4/12 text-2xl sm:text-35px lg:text-48px text-blacks-100 font-medium sm:leading-12 lg:leading-16"
                >
                    <v-content option_key="about_value_title" />
                </h3>
                <p class="lg:w-8/12 text-base lg:text-xl text-blacks-100">
                    <v-content option_key="about_value_content" />
                </p>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts"></script>

<style></style>
