<template>
    <section class="relative py-12 lg:py-24 bg-[#F8F8F8]">
        <div class="container relative">
            <Swiper
                :modules="[SwiperThumbs, SwiperNavigation]"
                :thumbs="{ swiper: thumbsSwiper }"
                :slidesPerView="1"
                :loop="true"
                :grabCursor="true"
                :navigation="{
                    nextEl: '.next-swiper-thumbs',
                    prevEl: '.prev-swiper-thumbs'
                }"
                class="overflow-hidden relative mb-3"
            >
                <SwiperSlide v-for="item in listThumbs" class="!h-[400px] lg:!h-[710px] w-full">
                    <div class="w-full h-full">
                        <NuxtImg
                            :src="getThumbnail(item?.image_bg, 1824, true)"
                            width="1824"
                            sizes="sm:100vw md:50vw lg:1824px "
                            loading="lazy"
                            :alt="item?.title"
                            :placeholder="[50, 25, 75, 5]"
                            class="w-full h-full object-cover"
                        />
                    </div>
                </SwiperSlide>
            </Swiper>
            <div class="">
                <Swiper
                    @swiper="setThumbsSwiper"
                    :watchSlidesProgress="true"
                    :grabCursor="true"
                    :loop="true"
                    :breakpoints="{
                        320: { slidesPerView: 3, spaceBetween: 10 },
                        1024: { slidesPerView: 8, spaceBetween: 12 }
                    }"
                    :modules="[SwiperThumbs]"
                    :space-between="32"
                    class="w-full !flex justify-between"
                >
                    <SwiperSlide
                        v-for="(item, index) in listThumbs"
                        :key="index"
                        class="cursor-pointer flex flex-col items-center swiper-about"
                    >
                        <div class="w-full h-[100px] md:h-[140px]">
                            <NuxtImg
                                :src="getThumbnail(item?.image_bg, 215, true)"
                                width="215"
                                sizes="sm:100vw md:50vw lg:215px "
                                loading="lazy"
                                :alt="item?.title"
                                :placeholder="[50, 25, 75, 5]"
                                class="w-full h-full object-cover"
                            />
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
            <div
                class="absolute top-[38%] z-10 swiper-navigation-thumbs flex items-center justify-between w-[calc(100%-30px)]"
            >
                <button
                    class="prev-swiper-thumbs lg:!-translate-x-15 w-8 h-8 lg:w-12 lg:h-12 text-white flex flex-col items-center justify-center bg-[#757575] hover:bg-blacks-100 duration-300"
                >
                    <span class="i-ic:sharp-keyboard-arrow-left text-2xl"></span>
                </button>
                <button
                    class="next-swiper-thumbs lg:!translate-x-15 w-8 h-8 lg:w-12 lg:h-12 text-white flex flex-col items-center justify-center bg-[#757575] hover:bg-blacks-100 duration-300"
                >
                    <span class="i-ic:sharp-keyboard-arrow-right text-2xl"></span>
                </button>
            </div>
        </div>
    </section>
</template>
<script setup lang="ts">
let thumbsSwiper = ref(null)
const setThumbsSwiper = (swiper: any) => {
    thumbsSwiper.value = swiper
}
const { getBanner } = useBanner()
const banners = await getBanner({ name: 'About' })
const listThumbs = ref(banners?.banners?.banner_items)
</script>
<style>
.swiper-about {
    opacity: 0.5;
}
.swiper-about.swiper-slide-thumb-active {
    opacity: 1;
}
</style>
