<template>
    <section class="py-12 lg:py-24">
        <div class="container">
            <div class="text-2xl sm:text-35px lg:text-5xl text-[#E6AF5D] font-medium mb-4 text-center">
                <v-content option_key="about_certification_title" />
            </div>
            <div class="text-[#474747] text-base max-w-[800px] mx-auto text-center mb-9">
                <v-content option_key="about_certification_text" />
            </div>
            <div class="grid grid-cols-2 gap-4 lg:gap-0 sm:grid-cols-3 lg:grid-cols-5">
                <div v-for="index in 5" class="max-w-50 mx-auto">
                    <div class="w-full">
                        <v-image
                            :option_key="`about_achievement_image${index}`"
                            :width="400"
                            class="w-full object-contain"
                        />
                    </div>
                    <div class="w-full">
                        <v-content
                            :option_key="`about_achievement_text${index}`"
                            class="text-[#191919] font-bold text-base text-center"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
