<template>
    <section>
        <div class="relative w-full flex flex-col items-center justify-start lg:min-h-[calc(100vh-80px)]">
            <div class="absolute inset-0 z-10">
                <v-image option_key="about_banner_image" class="w-full h-full object-cover" :width="1920" />
            </div>

            <div :class="isOpenVideo ? 'z-100' : 'z-1'" class="absolute inset-0">
                <div id="player" class="w-full h-full aspect-16/9"></div>
            </div>
            <div class="container relative z-10 py-15">
                <div class="w-full">
                    <div class="w-full">
                        <div v-if="!is_edit">
                            <div
                                v-html="convertedTextHeading"
                                ref="js_heading_about"
                                :class="'heading_' + route.name"
                                class="text-white"
                            ></div>
                        </div>
                        <div v-else>
                            <v-content option_key="about_banner_title" />
                        </div>
                    </div>
                    <div
                        data-aos="fade-up"
                        data-aos-once="true"
                        data-aos-delay="2000"
                        @click="isOpenVideo = true"
                        class="flex-none btn-effect-light w-20 sm:w-25 h-20 sm:h-25 md:w-33 md:h-33 col-flex rounded-full lg:mr-30 cursor-pointer"
                    >
                        <!-- <i class="i-ic:outline-play-arrow w-15 h-15 text-white"></i> -->
                        <img
                            src="/images/home/collection/play-video.svg"
                            alt="icon-play-video"
                            class="w-full h-full object-contain"
                        />
                    </div>
                    <div v-if="is_edit" class="text-white">
                        <v-text option_key="about_video_embed" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
const is_edit = inject('is_edit')
const data_live_editor = inject('data_live_editor')
const dataVideoEmbed = computed(() => {
    return data_live_editor.value.filter((item) => {
        return item.option_key.includes('about_video_embed')
    })
})

const isOpenVideo = ref(false)
const extractYouTubeVideoID = (url) => {
    const regExp = /^.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/
    const match = url.match(regExp)
    return match && match[1] ? match[1] : null
}

const youtubeLink = ref(dataVideoEmbed.value[0]?.option_value)
const videoID = extractYouTubeVideoID(youtubeLink.value)
const youtubeLinkRender = `https://www.youtube.com/embed/${videoID}?autoplay=1`
watch(
    () => isOpenVideo.value,
    (newValue) => {
        if (newValue) {
            onYouTubeIframeAPIReady()
        }
    }
)
var playerMain
function onYouTubeIframeAPIReady() {
    playerMain = new YT.Player('player', {
        videoId: videoID,
        playerVars: {
            playsinline: 1,
            playlist: videoID,
            loop: true,
            autoplay: 1,
            rel: 0,
            showinfo: 0,
            ecver: 2
        },

        events: {
            onReady: onPlayerReady,
            onStateChange: onPlayerStateChange
        }
    })
}
function onPlayerReady(event) {
    event.target.playVideo()
}

function onPlayerStateChange(event) {
    if (event.data !== 2) {
        isOpenVideo.value = true
    } else {
        isOpenVideo.value = false
    }
}
const route = useRoute()
const headingDefault = ref(getDataGlobalByKey('vi-VN', `CONTENT__${route.name}_banner_title`)?.option_value)

// const subHeadingDefault = ref(getDataGlobalByKey('vi-VN', `CONTENT__${route.name}_subHeading`)?.option_value)
const convertedTextHeading = computed(() => headingDefault.value)
const js_heading_about = ref<HTMLElement>([])
onMounted(() => {
    if (!is_edit) {
        const wordsHeading = convertedTextHeading.value

        js_heading_about.value.innerHTML = ''
        document.querySelectorAll(`.heading_${route.name}`).forEach((item: any) => item.classList.add('active'))
        wordsHeading.split(/(<\>)/).forEach(function (word) {
            const div = document.createElement('div')
            // console.log('🚀 ~ file: SectionBanner.vue:91 ~ div:', div)
            div.innerHTML = word + ' '
            js_heading_about.value.appendChild(div)
            js_heading_about.value.querySelectorAll('div').forEach(function (div, index) {
                div.querySelectorAll('p , li').forEach(function (content, index) {
                    content.textContent.split(' ').forEach((word: string) => {
                        const span = document.createElement('span')
                        span.innerText = word + ' '
                    })
                    // content.parentNode.replaceChild(blockAnimation, content)
                })
                div.classList.add('inline', 'leading-50px', 'sub-heading-collection-custom')

                div.style.animation = `fade-in-heading 1s ${0.1 * index}s forwards cubic-bezier(0.11, 0, 0.5, 0)`
            })
        })
    }
})
</script>

<style></style>
