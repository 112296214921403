<template>
    <section class="relative">
        <div class="absolute right-0 bottom-0 max-w-[688px] max-h-[688px] transform z-1">
            <!-- <img src="/images/about/stamp.png" alt="" class="object-cover w-full h-full" /> -->
            <v-image option_key="about_history_thumbnail`" alt="Banner" class="w-full h-full object-cover" />
        </div>
        <div class="container py-12 lg:py-24">
            <h2 class="text-lg text-blacks-50 mb-3">
                <v-content option_key="about_history_subtitle" />
            </h2>
            <h1 class="text-[#101828] mb-5 text-2xl sm:text-35px lg:text-48px font-medium sm:leading-12 lg:leading-16">
                <v-content option_key="about_history_title" />
            </h1>
            <div class="text-[#475467] text-lg mb-8 lg:mb-16 max-w-[768px]">
                <v-content option_key="about_history_desc" />
            </div>
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16 relative z-10">
                <div class="">
                    <v-content option_key="about_history_content1" />
                </div>
                <div class="">
                    <v-content option_key="about_history_content2" />
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts"></script>

<style></style>
