<template>
    <section>
        <div class="container py-12 lg:py-24">
            <div class="flex flex-col lg:flex-row gap-6 lg:gap-12">
                <div class="w-full lg:w-1/2">
                    <div class="flex items-center gap-3">
                        <div class="w-6 h-6 flex-none">
                            <v-image option_key="about_vision_icon1" class="w-full h-full object-cover" />
                        </div>
                        <div class="w-full text-2xl md:text-4xl font-medium text-blacks-100">
                            <v-content option_key="about_vision_title1" />
                        </div>
                    </div>
                    <div class="text-base lg:text-xl text-blacks-100 mt-3 lg:mt-6">
                        <v-content option_key="about_vision_text1" />
                    </div>
                </div>
                <div class="w-full lg:w-1/2">
                    <div class="flex items-center gap-3">
                        <div class="w-6 h-6 flex-none">
                            <v-image option_key="about_vision_icon2" class="w-full h-full object-cover" />
                        </div>
                        <div class="w-full text-2xl md:text-4xl font-medium text-blacks-100">
                            <v-content option_key="about_vision_title2" />
                        </div>
                    </div>
                    <div class="text-base lg:text-xl text-blacks-100 mt-3 lg:mt-6">
                        <v-content option_key="about_vision_text2" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts"></script>

<style></style>
